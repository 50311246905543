export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js"),
	() => import("/.svelte-kit/generated/client/nodes/27.js"),
	() => import("/.svelte-kit/generated/client/nodes/28.js"),
	() => import("/.svelte-kit/generated/client/nodes/29.js"),
	() => import("/.svelte-kit/generated/client/nodes/30.js"),
	() => import("/.svelte-kit/generated/client/nodes/31.js"),
	() => import("/.svelte-kit/generated/client/nodes/32.js"),
	() => import("/.svelte-kit/generated/client/nodes/33.js"),
	() => import("/.svelte-kit/generated/client/nodes/34.js"),
	() => import("/.svelte-kit/generated/client/nodes/35.js"),
	() => import("/.svelte-kit/generated/client/nodes/36.js"),
	() => import("/.svelte-kit/generated/client/nodes/37.js"),
	() => import("/.svelte-kit/generated/client/nodes/38.js"),
	() => import("/.svelte-kit/generated/client/nodes/39.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [13],
		"/cups": [14],
		"/cups/[slug]": [15,[2]],
		"/ff": [16],
		"/managers": [17],
		"/managers/[slug]": [18,[3]],
		"/misc": [19,[4]],
		"/misc/[slug]": [20,[4]],
		"/players": [21,[5]],
		"/players/[slug]": [22,[5,6]],
		"/records": [23,[7]],
		"/records/Overall-Leaderboards": [24,[7]],
		"/records/[slug]": [25,[7,8]],
		"/stream": [26,[9]],
		"/teams": [27,[10]],
		"/teams/[slug]": [28,[10,11]],
		"/tools": [29,[12]],
		"/tools/cache": [30,[12]],
		"/tools/ff_teams": [31,[12]],
		"/tools/groupstage": [32,[12]],
		"/tools/icons": [33,[12]],
		"/tools/logs": [34,[12]],
		"/tools/maintenance": [35,[12]],
		"/tools/unlinked_players": [36,[12]],
		"/tools/unlinked_stadiums": [37,[12]],
		"/tools/upload": [38,[12]],
		"/tools/users": [39,[12]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";